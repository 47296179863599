import React from 'react';

import Layout from '~/components/Layout';
import Seo from '~/components/seo';

import Banner from '~/components/Service/Banner';
import AppDownload from '~/components/Service/AppDownload';
import DabangServices from '~/components/Service/DabangServices';

export default function ServicePage() {
  return (
    <Layout>
      <div className="Wrap">
        <Banner />
        <AppDownload />
        <DabangServices />
      </div>
    </Layout>
  );
}

export function Head() {
  return (
    <Seo title="스테이션3 서비스" />
  );
}
